/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
/* @import '~@angular/material/prebuilt-themes/deeppurple-amber.css'; */
@import url(./assets/css/style.css);

.none {
    display: none !important;
}

::placeholder,
.mat-mdc-select-placeholder {
    color: #637381;
    font-weight: 500;
    font-size: 14px;
}

::-ms-input-placeholder,
.mat-mdc-select-placeholder {
    /* Edge 12 -18 */
    color: #637381;
    font-weight: 500;
    font-size: 14px
}

.ng-touched.ng-invalid {
    border-color: red !important;
}

.error-message {
    color: red;
    font-size: 12px;
    margin: 3px 0 0;
    display: inline-block;
}

input:disabled {
    cursor: no-drop;
}

html,
body {
    height: 100%;
}

/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */
.custom-select-ui {
    /* background: #ccc; */
}

mat-form-field.mat-mdc-form-field.custom-select-ui.ng-tns-c1205077789-0.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill.mat-form-field-hide-placeholder.mat-primary.ng-star-inserted {}

.custom-select-ui .mat-mdc-form-field-subscript-wrapper {
    display: none;
}

.custom-select-ui .mat-mdc-form-field-infix {
    min-height: 10px;
}

.custom-select-ui .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 20px;
}

.custom-select-ui .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    /* padding-top: 4px;
    padding-bottom: 8px; */
    padding-top: 0px;
    padding-bottom: 0px;
}

.custom-select-ui.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
    transform: translateY(-8px);
    top: 0px;
    position: relative;
}

.custom-select-ui .mdc-line-ripple::before,
.custom-select-ui .mdc-line-ripple::after {
    border-bottom-width: 0 !important;
}

.custom-select-ui .mat-mdc-text-field-wrapper {
    width: 100%;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
}

.custom-select-ui .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #fff;
}

/* div.mat-mdc-select-panel{
    margin-left: -12px;
} */
.mdc-list-item,
div.mat-mdc-select-panel.filter-option {
    overflow: visible !important;
}

.mat-mdc-option.mdc-list-item--disabled {
    cursor: default !important;
    pointer-events: auto !important;
}

.mat-mdc-option.mdc-list-item--disabled .mdc-list-item__primary-text {
    opacity: 1 !important;
}

.mdc-list-item.mdc-list-item--disabled,
.mdc-list-item.mdc-list-item--non-interactive {
    cursor: pointer !important;
}

.mat-mdc-select-min-line {
    font-size: 14px;
    padding: 6px;
}

.mdc-checkbox,
.mdc-checkbox__native-control,
.mat-mdc-checkbox-touch-target,
.mdc-checkbox__background {
    width: 15px !important;
    height: 15px !important;
}

.mat-mdc-checkbox {
    position: absolute;
    top: -5px;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    border-color: #F37A20 !important;
    background-color: #F37A20 !important;
}

.cdk-overlay-pane {
    /* width: auto !important; */
}

.mat-mdc-option .mdc-list-item__primary-text {
    font-size: 14px !important;
}

.mat-mdc-option {
    min-height: 35px !important;
}

.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: #F37A20 !important;
}

.mat-mdc-option.mdc-list-item:hover {
    /* background-color: #fab575 !important;
  color: rgb(255, 255, 255) !important; */
}

.mat-mdc-option.mdc-list-item:hover .mat-option-text {
    color: rgb(255, 255, 255) !important;
}


.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
    background-color: #ddd !important;
    color: #fff !important;
}

/* ::ng-deep .mat-mdc-option{
    width: 250px !important;
} */

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure it's above other content */
}

/* 
.overlay-main {
    position: fixed;
   
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
} */

.material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
}

.cdk-overlay-pane {
    /* min-height: 600px !important; */
}

.disabled {
    pointer-events: none;
    background: #e0e0e0;
    opacity: 0.7;
}

::ng-deep .mat-snack-bar-container.success-snackbar {
    background-color: green;
    color: white;
}


.error-snackbar {
    background-color: red;
    color: white;
}

.warning-snackbar {
    background-color: orange;
    color: white;
}

.arrow-img {
    width: 20px;
    height: 20px;
    margin-right: 10px
}

.arrow {
    cursor: pointer;
    display: flex;
    align-items: center;
}

/* Remove number input spinner arrows */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    /* Optional - if you want to remove the margin as well */
}

/* Optional: Ensure the text input aligns properly with other form elements */
input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

.Printall-label svg {
    position: relative;
    top: -1px;
}

.search-path.page-header a svg {
    display: none;
}

.search-path.page-header img {
    display: block;
}

@media screen and (max-width:767px) {
    .search-path.page-header a svg {
        display: block;
        width: 14px;
        height: 14px;
    }

    .search-path.page-header a {
        margin-bottom: 10px;
        /* width: 118px; */
        margin: 0 0 10px 10px !important;
        /* margin-bottom: 0; */
        width: 34px !important;
        min-width: auto !important;
        font-size: 0;
        padding: 0 !important;
        height: 30px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn {
        width: 100%;
        /* margin-top: -50px; */
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .search-path.page-header a svg {
        display: block;
    }

    .search-set {
        margin-bottom: 10px;
    }

    .mat-expansion-panel-body {
        padding: 5px 5px 10px 5px !important;
    }

    .search-path.page-header img {
        display: none;
    }

   .cdk-overlay-pane{
        max-width: calc(100vw - 20px) !important;
   }
}